// Task list pattern

.app-task-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  @include govuk-media-query($from: tablet) {
    min-width: 550px;
  }
}

.app-task-list__section {
  display: table;
  @include govuk-font($size: 24, $weight: bold);
}

.app-task-list__section-number {
  display: table-cell;

  @include govuk-media-query($from: tablet) {
    min-width: govuk-spacing(6);
    padding-right: 0;
  }
}

.app-task-list__items {
  @include govuk-font($size: 19);
  @include govuk-responsive-margin(9, "bottom");
  list-style: none;
  padding-left: 0;
  @include govuk-media-query($from: tablet) {
    padding-left: govuk-spacing(6);
  }
}

.app-task-list__items_bottom-padding {
  @include govuk-font($size: 19);
  @include govuk-responsive-margin(2, "bottom");
  list-style: none;
  padding-left: 0;
  @include govuk-media-query($from: tablet) {
    padding-left: govuk-spacing(6);
  }
}

.app-task-list__item {
  border-bottom: 1px solid $govuk-border-colour;
  margin-bottom: 0 !important;
  padding-top: govuk-spacing(2);
  padding-bottom: govuk-spacing(2);
  @include govuk-clearfix;
}

.app-task-list__item:first-child {
  border-top: 1px solid $govuk-border-colour;
}

.app-task-list__task-name {
  display: block;
  @include govuk-media-query($from: 450px) {
    float: left;
  }
}

// The `app-task-list__task-completed` class was previously used on the task
// list for the completed tag (changed in 86c90ec) – it's still included here to
// avoid breaking task lists in existing prototypes.
.app-task-list__tag,
.app-task-list__task-completed {
  margin-top: govuk-spacing(2);
  margin-bottom: govuk-spacing(1);

  @include govuk-media-query($from: 450px) {
    float: right;
    margin-top: 0;
    margin-bottom: 0;
  }
}
