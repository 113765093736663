/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: #1d70b8;
}

.tooltip:hover {
  color: #1d70b8;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  text-align: left;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: white;

  /* Position the tooltip text */
  position: absolute;
  bottom: 70%;
  /* Fade in tooltip */
  cursor: auto;
  opacity: 0;
  transition: opacity 0.3s;
  border: 2px solid black;
  color: #0b0c0c;
  width: 300px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

// override tooltiptext position for the observations
.tooltip div.tooltiptext{
    bottom: 130%;
}

/* Tooltip arrow */
.tooltip .tooltiptext::before,
.tooltip .tooltiptext::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: " ";
  position: absolute;
  top: 100%;
  left: 5%;

  // margin-left: -5px;
  // border-bottom: 10px solid #333;
  border-width: 10px;
  width: 0;
  border-style: solid;
  // border-left: 5px solid transparent;
  border-color: #555 transparent transparent transparent;
  // height: 0;
}
.tooltip .tooltiptext::before {
  border-top: 10px solid black;
  margin-top: 1px;
}

.tooltip .tooltiptext::after {
  border-top: 10px solid white;
  margin-top: -2px;
  z-index: 1;
}

.showtooltip {
  visibility: visible !important;
  opacity: 1 !important;
}

// over-ride class
.timetable_govuk_body {
  overflow: visible !important;
}

#timetable_tooltip > div > div.govuk-accordion__section-header > span > button {
  padding-bottom: 0px;
}

span.text-color-red{
  color: #d4351c;
}
