.disruptions-map {
  .mapboxgl-popup-content {
    font: 400 12px/18px "GDS Transport", arial, sans-serif;
    padding: 0;
    width: 300px;
  }

  .mapboxgl-popup-content h2 {
    background: #005EA5;
    color: #fff;
    margin: 0;
    padding: 10px;
    border-radius: 6px 6px 0 0;
    font-weight: 600;
    margin-top: -15px;
  }

  .mapboxgl-popup-content a:link {
    color: #1d70b8;
  }

  .mapboxgl-popup-content a:visited {
    color: #4c2c92;
  }

  .mapboxgl-popup-content h3 {
    background: #005EA5;
    color: #fff;
    margin: 0;
    padding: 10px;
    border-radius: 6px 6px 0 0;
    font-weight: 400;
    margin-top: -15px;
  }

  .mapboxgl-popup-content div {
    padding: 10px;
    border-radius: 0 0 6px 6px;
    font-weight: 700;
    background: #ffffff;
    border: 2px solid #005EA5;
  }

  .mapboxgl-popup-content a {
    border: none;
  }

  .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
    margin-top: 15px;
  }

  .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #005EA5;
  }

  .mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    border-top-color: #005EA5;
  }
}
