.app-masthead {
  @include govuk-text-colour;
  padding-top: 0.1px;
  padding-bottom: 20px;
  border-bottom: 1px solid govuk-colour("blue");
  color: govuk-colour("white");
  background-color: govuk-colour("blue");
  margin-top: 16px;

  .govuk-body {
    color: govuk-colour("white") !important;

    a {
      color: govuk-colour("white") !important;
    }
  }

  .app-button--inverted,
  .app-button--inverted:link,
  .app-button--inverted:visited {
    color: govuk-colour("blue");
    background-color: govuk-colour("white");
    -webkit-box-shadow: 0 2px 0 #144e81;
    box-shadow: 0 2px 0 #144e81;
  }

  .app-masthead__title {
    color: govuk-colour("white") !important;
    margin-bottom: 20px;
    white-space: nowrap;
  }
}
