
// Not sure if code above is used but this is to trim the extra padding on the swagger docs
#swagger-ui .wrapper {
  padding: 0;
}

.api-url-panel {
  display: flex;
  justify-content: space-between;
  border: 0.15rem solid map-get($govuk-colours, "light-grey");
  padding: govuk-spacing(5);

  &__url {
    @include govuk-font($size: 19);
    color: map-get($govuk-colours, "dark-grey");
  }
}
