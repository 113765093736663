.help-modal {
  display: inline;

  .help-icon {
    color: $govuk-link-colour;
    cursor: pointer;
  }

  .help-icon-white {
    color: white;
    cursor: pointer;
  }

  .overlay {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;

    .window {
      position: relative;
      width: 100%;
      max-width: 75%;
      background: $govuk-body-background-colour;

      .close-button {
        position: absolute;
        right: 0;
        margin: govuk-spacing(5);
        border: 0;
        border-style: none;
        background-color: $govuk-body-background-colour;
      }

      .content {
        position: relative;
        margin: 2 * govuk-spacing(5) govuk-spacing(5);
      }
    }
  }
}
