.range-filter {
  list-style: none;
  margin: 0 0 2rem 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: "nta", Arial, sans-serif;
  font-size: 1.125rem;

  >li {
    margin: 0;
    padding: 0;

    &:not(:last-child):after {
      content: "|";
      margin: auto;
      padding: 0 2rem;
      color: $govuk-green-colour;
    }
  }
}

.checkbox-filter {
  $filter-border-colour: govuk-colour("light-grey");
  border: 2px solid $filter-border-colour;

  .govuk-fieldset__legend {
    font-weight: unset;
    background: $filter-border-colour;
    width: 100%;
    padding: govuk-spacing(1);
  }

  .checkbox-filter__annotation {
    color: govuk-colour("dark-grey");
  }
}
