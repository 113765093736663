/* Pillbox */

$light-grey-25: mix(white, govuk-colour("light-grey"), 25%);
$light-grey-50: mix(white, govuk-colour("light-grey"), 50%);

.search-pillbox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  background-color: $light-grey-25;
  width: initial;
  min-height: govuk-spacing(7);
  padding: govuk-spacing(3);
  border: 0;
  margin: 0;

  a {
    text-decoration: none;
  }

  .pill-item {
    border: solid 1px govuk-colour("dark-grey");
    background-color: $light-grey-50;
    border-radius: 4px;
    padding: govuk-spacing(1);
    display: inline-flex;
    justify-content: flex-start;

    .pill-item__label {
      padding: 5px;
      color: govuk-colour("black");
    }
    .pill-item__image {
      padding: 5px;
      align-self: center;
    }
  }
}

.pillbox-break {
  border-bottom-color: govuk-colour("black");
  margin-bottom: govuk-spacing(3);
}

/* search box */
$search-box-height: 45px;

.searchbox {
  width: 100%;
  display: flex;
  margin-bottom: govuk-spacing(6);

  .searchbox__input {
    @extend .govuk-input;
    width: 100%;
    height: $search-box-height;
    z-index: 1;
  }

  .searchbox__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: govuk-colour("black");
    height: $search-box-height;
    width: $search-box-height;
    border: 0;
  }
}

/* search results */

.search-result__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;

  span {
    white-space: pre;
  }
}

.search-list__page-index {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
}

.search-result__title {
  display: inline-block;
  margin-right: 1.375rem;
  margin-bottom: 0; /* Note remove bottom margin from h3 tag as it doesn't collapse with metadata list */
}

.search-result__list {
}

.search-result {
  margin-bottom: 3rem;
}

.autocomplete {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 3px solid govuk-colour("black");
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  background-color: $light-grey-25;
  color: govuk-colour("black");
  font-family: "GDS Transport", arial, sans-serif;
  font-size: 19px;
  font-size: 1.1875rem;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: govuk-colour("white");
  border-bottom: 1px solid govuk-colour("light-grey");
}

.autocomplete-items div:hover {
  background-color: govuk-colour("dark-blue") !important;
  color: govuk-colour("white");
}

// Used by javascript to highlight option
.autocomplete-active {
  background-color: govuk-colour("dark-blue") !important;
  color: govuk-colour("white");
}
