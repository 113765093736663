.dropdown {
  /* Dropdown menu*/
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.js-enabled {
  .dropdown {
    .dropdown-content {
      // display: none;

      &.open {
        display: block;
      }
    }
  }
}
